.blink_me {
  animation: blinker 1s linear infinite;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

.spinner {
    animation: spin infinite 2s linear;
  }

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

html, body {
  height: 100%;
  background-color: #EDEDED;
}

.h-nav {
  min-height: calc(100vh - 36px);
  max-height: calc(100vh - 36px);
}

.h-nav::-webkit-scrollbar {
  width: 4px;
  cursor: pointer;
  /*background-color: rgba(229, 231, 235, var(--bg-opacity));*/

}
.h-nav::-webkit-scrollbar-track {
  background-color: rgba(229, 231, 235, var(--bg-opacity));
  cursor: pointer;
  /*background: red;*/
}
.h-nav::-webkit-scrollbar-thumb {
  cursor: pointer;
  background-color: #a0aec0;
  /*outline: 1px solid slategrey;*/
}

.h-dlg-height {
  min-height: calc(100vh - 0px);
}

.h-body {
  min-height: calc(100vh - 36px);
  max-height: calc(100vh - 36px);
}

.w-body {
  min-width: calc(100% - 2400px);
  max-width: calc(100% - 240px);
}

.max-rep-height {
  min-height: calc(100vh - 30px);
}

.default-rep-height {
  min-height: calc(100vh - 240px);
}

.animate-width {
  -webkit-transition: width 0.25s ease-in-out;
    -moz-transition: width 0.25s ease-in-out;
    -o-transition: width 0.25s ease-in-out;
    transition: width 0.25s ease-in-out;
}

.animate-left {
  -webkit-transition: all 0.25s ease-in-out;
    -moz-transition: all 0.25s ease-in-out;
    -o-transition: all 0.25s ease-in-out;
    transition: all 0.25s ease-in-out;
}

/* width */
.scrollbar-narrow::-webkit-scrollbar {
  width: 3px;
}

/* Track */
.scrollbar-narrow::-webkit-scrollbar-track {
  background: #fff; 
}
 
/* Handle */
.scrollbar-narrow::-webkit-scrollbar-thumb {
  background: #888; 
}

/* Handle on hover */
.scrollbar-narrow::-webkit-scrollbar-thumb:hover {
  background: #555; 
}

.cursor-v-resize {cursor: col-resize;}

.center-content-shadow {
  -moz-box-shadow-bottom: 18px 18px 40px 1px rgba(50, 50, 50, .1);
  -webkit-box-shadow-bottom: 18px 18px 40px 1px rgba(50, 50, 50, .1);
  box-shadow: 18px 18px 40px 1px rgba(50, 50, 50, .1);
}

.td-p-3-7 {
  padding-left: 3px;
  padding-right: 3px;
  padding-top: 7px;
  padding-bottom: 7px;
}

.td-p-3-8 {
  padding-left: 3px;
  padding-right: 3px;
  padding-top: 8px;
  padding-bottom: 8px;
}

.Resizer {
  background: #000;
  opacity: 0.2;
  z-index: 1;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -moz-background-clip: padding;
  -webkit-background-clip: padding;
  background-clip: padding-box;
}

.Resizer:hover {
  -webkit-transition: all 2s ease;
  transition: all 2s ease;
}

.Resizer.horizontal {
  height: 11px;
  margin: -5px 0;
  border-top: 5px solid rgba(255, 255, 255, 0);
  border-bottom: 5px solid rgba(255, 255, 255, 0);
  cursor: row-resize;
  width: 100%;
}

.Resizer.horizontal:hover {
  border-top: 5px solid rgba(0, 0, 0, 0.5);
  border-bottom: 5px solid rgba(0, 0, 0, 0.5);
}

.Resizer.vertical {
  width: 12px;
  margin: 0 -5px;
  border-left: 5px solid rgba(255, 255, 255, 0);
  border-right: 5px solid rgba(255, 255, 255, 0);
  cursor: col-resize;
}

.Resizer.vertical:hover {
  border-left: 5px solid #5f1923;
  border-right: 5px solid #5f1923;
}
.Resizer.disabled {
  cursor: not-allowed;
}
.Resizer.disabled:hover {
  border-color: transparent;
}

/* mobile menu styles */
.mobile-menu-toggle {
  width: 20px;
  height: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;
}

.mobile-menu-toggle span {
  display: block;
  height: 2px;
  background-color: #555;
}